import styled, { css } from 'styled-components';
import bgLeft from '../../../images/poly-left.svg';
import bgRight from '../../../images/poly-right.svg';
import checkmark from '../../../images/checkmark-revert.svg';
import closemark from '../../../images/closeDark.svg';
import plusmark from '../../../images/plusDark.svg';
import caret from '../../../images/caret.svg';

import { font } from '../../atoms/typography';

export const ComponentLibBody = styled.div`
  background: #f9fbfe;
  padding: 96px 0;
  @media (max-width: 991px) {
    padding: 64px 0;
  }
`;
export const ComponentFilter = styled.div`
  margin-bottom: 48px;
  .dropdown-section {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    @media (max-width: 991px) {
      width: 50%;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      &:first-child {
        margin-bottom: 16px;
      }
    }
    .clear-filter {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      background: #1c3eff;
      color: #fff;
      font-size: 10px;
      padding: 1px;
      text-align: center;
      position: absolute;
      top: 14px;
      right: 16px;
      cursor: pointer;
      opacity: 0;
      transition: 0.1s;
    }
    .dropdown {
      button {
        width: 270px;
        height: 46px;
        text-align: left;
        background-color: #ffffff !important;
        border-radius: 2px;
        padding: 16px;
        ${font('overline', 'sm', '700')}
        color: #1d2030 !important;
        border-color: transparent !important;
        letter-spacing: 2px !important;
        filter: drop-shadow(0px 1px 3px rgba(19, 48, 205, 0.1))
          drop-shadow(0px 1px 2px rgba(19, 48, 205, 0.06));
        &:focus {
          box-shadow: unset !important;
        }
        &:after {
          position: absolute;
          right: 16px;
          top: 19px;
          color: #1c3eff;
          border-top: 5px solid !important;
          border-right: 4px solid transparent !important;
          border-left: 4px solid transparent !important;
        }
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      .dropdown-menu {
        width: 100%;
        border: none;
        border-radius: 0;
        padding: 0;
        box-shadow: 0px 12px 16px -4px rgba(19, 48, 205, 0.1),
          0px 4px 6px -2px rgba(19, 48, 205, 0.05);
        overflow: auto;
        max-height: 400px;
        .dropdown-item {
          ${font('overline', 'sm', '700')}
          color: #1d2030;
          padding: 16px;
          &:hover {
            background: #f9fbfe;
            color: #1c3fff;
          }
        }
      }
    }
    &:hover {
      .clear-filter {
        opacity: 1;
      }
    }
  }
  #search-box {
    height: 46px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    padding-left: 44px;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%237B848F%22%20d%3D%22M508.5%20481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395%20312%20416%20262.5%20416%20208%20416%2093.1%20322.9%200%20208%200S0%2093.1%200%20208s93.1%20208%20208%20208c54.5%200%20104-21%20141.1-55.2V371c0%203.2%201.3%206.2%203.5%208.5l129%20129c4.7%204.7%2012.3%204.7%2017%200l9.9-9.9c4.7-4.7%204.7-12.3%200-17zM208%20384c-97.3%200-176-78.7-176-176S110.7%2032%20208%2032s176%2078.7%20176%20176-78.7%20176-176%20176z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px;
    &:hover {
      border-color: #0015ca;
    }
    &:focus {
      box-shadow: none;
      border-color: var(--primary-color);
      background-color: #f9fbfe;
    }
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
  .lg-reserve {
    @media (max-width: 991px) {
      order: 12;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
export const ComponentList = styled.div`
  h4 {
    margin-bottom: 48px;
  }
`;
export const ComponentCard = styled.div`
  background: #ffffff;
  border: 0.5px solid #d8dae6;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 32px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    box-shadow: 0px 24px 48px -12px rgba(19, 48, 205, 0.25);
  }
  figure {
    img {
      width: 100%;
      height: 185px;
      object-fit: cover;
      object-position: center;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
  .body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .heading {
      ${font('display', 'sm', '700')}
      color: #1d2030;
      margin-bottom: 16px;
    }
    .subhead {
      ${font('text', 'lg', '400')}
      color: #6f7283;
      margin-bottom: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .variation {
      ${font('text', 'sm', '400')}
      color: #6f7283;
      margin-bottom: 0;
    }
  }
`;
export const ComponentDetailHero = styled.div`
  padding: 16px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  height: 218px;
  &:before {
    pointer-events: none;
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0px;
    top: -70px;
    background-image: url(${bgLeft});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 991px) {
      top: -100px;
    }
    @media (max-width: 768px) {
      top: -120px;
    }
  }
  &:after {
    pointer-events: none;
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    right: 0px;
    bottom: -10px;
    background-image: url(${bgRight});
    background-repeat: no-repeat;
    background-size: cover;
  }
  img {
    max-width: 188px;
    max-height: 28px;
  }
  h1 {
    color: #fff;
    text-align: left;
    font-weight: 700;
    font-size: 28.13px;
    line-height: 120%;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 15px;
  }
`;
export const ComponentSection = styled.div`
  background: ${props => (props.Light ? '#f9fbfe' : '#ffffff')};
  padding: 96px 0;
  @media (max-width: 991px) {
    padding: 64px 0;
  }
  .breadcrumb {
    a {
      color: var(--text-color);
    }
  }
  .componentDesc {
    max-width: 770px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.27px;
    color: var(--text-color);
    margin-bottom: 32px;
  }
  .componentSample {
    background: #ffffff;
    position: relative;
    box-shadow: 0px 1px 3px rgba(19, 48, 205, 0.1),
      0px 1px 2px rgba(19, 48, 205, 0.06);
    max-width: ${props =>
      props.breackpoint === '2'
        ? '991px'
        : props.breackpoint === '3'
        ? '576px'
        : '100%'};
    margin: 0 auto;
    .initial-load {
      .custom-space {
        padding: 64px 30px !important;
        margin: 0 auto !important;
        width: 100%;
        &.fullwidth-space {
          padding: 0 !important;
        }
        .container-wrapper {
          padding: ${props =>
            props.breackpoint === '2'
              ? '63px 80px'
              : props.breackpoint === '3'
              ? '63px 20px'
              : '63px 0'} !important;
        }
      }
    }
    #accordion {
      @media (min-width: 991px) {
        .col-lg-4 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '33.33333%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '33.33333%'};
        }
        .col-lg-8 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '66.66%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '66.66%'};
        }
      }
      .accordion {
        margin-left: ${props =>
          props.breackpoint === '2'
            ? '0'
            : props.breackpoint === '3'
            ? '0'
            : '8.3%'};
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    #awards {
      @media (min-width: 991px) {
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
      }
      .heading-content {
        text-align: ${props =>
          props.breackpoint === '2'
            ? 'center'
            : props.breackpoint === '3'
            ? 'center'
            : 'left'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '100%'
            : props.breackpoint === '3'
            ? '100%'
            : '570px'};
        margin-bottom: ${props =>
          props.breackpoint === '2'
            ? '24px'
            : props.breackpoint === '3'
            ? '24px'
            : '0'};
      }
      .gatsby-image-wrapper {
        margin: ${props =>
          props.breackpoint === '2'
            ? '0 auto'
            : props.breackpoint === '3'
            ? '0 auto'
            : '0'};
      }
    }
    #card-decks {
      .subhead {
        margin-bottom: 32px;
      }
      .card-wrapper {
        margin-bottom: 32px;
      }
      .history-cards-wrapper {
        .slick-slide {
          width: ${props =>
            props.breackpoint === '2'
              ? '345px !important'
              : props.breackpoint === '3'
              ? '346px !important'
              : 'auto !important'};
        }
        .history-date {
          font-size: 14.016px !important;
        }
        .history-subhead {
          p {
            font-size: 16px !important;
          }
        }
      }
      .heading-info {
        .heading {
          margin-bottom: 32px;
        }
      }
    }
    #case-studies {
      .custom-space {
        min-height: 100%;
      }
      @media (min-width: 991px) {
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
        .col-lg-4,
        .col-md-4 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '33.33333%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '33.33333%'};
        }
        .ref-col {
          order: ${props =>
            props.breackpoint === '2'
              ? '12'
              : props.breackpoint === '3'
              ? '12'
              : 'initial'};
        }
      }
    }
    #careers {
      @media (min-width: 991px) {
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
      }
    }
    #conversion-panel {
      .conversion-heading {
        font-size: ${props =>
          props.breackpoint === '2'
            ? '1.953rem'
            : props.breackpoint === '3'
            ? '22.5px'
            : '1.953rem'} !important;
      }
      .conversion-kicker {
        font-size: 12px !important;
      }
      .logos {
        margin-bottom: ${props =>
          props.breackpoint === '2'
            ? '24px'
            : props.breackpoint === '3'
            ? '24px'
            : '0'};
      }
      @media (min-width: 991px) {
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
      }
    }
    #exit-intent-popup {
      #exitIntent {
        position: relative !important;
        height: auto !important;
        z-index: 1 !important;
      }
      .form-desc {
        min-height: ${props =>
          props.breackpoint === '2'
            ? '500px'
            : props.breackpoint === '3'
            ? '300px'
            : '500px'};
      }
    }
    #features {
      .heading-info {
        margin: 0 auto !important;
        padding: 0 !important;
      }
      .lists-wrapper {
        display: ${props =>
          props.breackpoint === '2' || props?.breackpoint === '3'
            ? 'flex'
            : 'grid'};
        .list-subhead {
          max-width: ${props =>
            props.breackpoint === '2'
              ? '270px !important'
              : props.breackpoint === '3'
              ? '100% !important'
              : '270px'};
        }
      }
    }
    #footer {
      .offset-lg-1 {
        margin-left: 0;
      }
      @media (min-width: 991px) {
        .col-lg-3 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '25%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '25%'};
        }
      }
      .privacy {
        top: ${props =>
          props.breackpoint === '2'
            ? '0'
            : props.breackpoint === '3'
            ? '22px'
            : '0'};
      }
    }
    #header {
      @media (min-width: 991px) {
        .col-lg-8,
        .col-md-8 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '66.66%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '66.66%'};
        }
      }
    }
    #hero {
    }
    #switchbacks {
      @media (min-width: 991px) {
        .col-lg-5 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '41.66667%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '41.66667%'};
        }
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
        .col-lg-7 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '58.33333%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '58.33333%'};
        }
      }
      .offset-lg-1 {
        margin-left: 0;
      }
      figure {
        text-align: center;
      }
      .gatsby-image-wrapper {
        max-width: 486px;
        display: block;
        margin: 0 auto;
      }
      img {
        max-width: 486px;
      }
      .content-text {
        order: ${props => (props.reverse === '1' ? '12' : '1')};
      }
      .heading-desc {
        order: ${props => (props.reverse === '1' ? '12' : '1')};
      }
      .heading-media {
        order: ${props => (props.reverse === '1' ? '1' : '12')};
        padding: 24px;
      }
    }
    #testimonials {
      .standard-card {
        p {
          font-size: 16px !important;
        }
      }
      .split-area {
        h2 {
          font-size: 2.441rem !important;
        }
      }
      .split-swiper {
        padding: ${props =>
          props.breackpoint === '2'
            ? '50px 50px 80px 50px'
            : props.breackpoint === '3'
            ? '50px 0px 80px 0px'
            : '50px 50px 80px 50px'};
      }
      .split-card {
        .quote {
          p {
            font-size: 18px !important;
          }
        }
      }
      .testimonials {
        .swiper-slide {
          width: ${props =>
            props.breackpoint === '2'
              ? '350px !important'
              : props.breackpoint === '3'
              ? '350px !important'
              : 'auto'};
        }
      }
    }
    #video-player {
      @media (min-width: 991px) {
        .col-lg-5 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '41.66667%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '41.66667%'};
        }
        .col-lg-6 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '50%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        }
        .col-lg-7 {
          flex: 0 0
            ${props =>
              props.breackpoint === '2'
                ? '100%'
                : props.breackpoint === '3'
                ? '100%'
                : '58.33333%'};
          max-width: ${props =>
            props.breackpoint === '2'
              ? '100%'
              : props.breackpoint === '3'
              ? '100%'
              : '58.33333%'};
        }
      }
      .offset-lg-1 {
        margin-left: ${props =>
          props.breackpoint === '2'
            ? '0'
            : props.breackpoint === '3'
            ? '0'
            : '8.3%'};
      }
      .reverse-image {
        order: ${props =>
          props.breackpoint === '2'
            ? '12'
            : props.breackpoint === '3'
            ? '12'
            : '1'};
      }
    }
    #video-switcher {
      .library-modal {
        padding: 0px !important;
        .modal {
          position: relative !important;
          max-height: ${props =>
            props.breackpoint === '2'
              ? '675px'
              : props.breackpoint === '3'
              ? '715px'
              : '675px'};
          z-index: 1 !important;
          @media (max-width: 991px) {
            max-height: 755px;
            height: auto;
          }
          .modal_wrap {
            margin-top: 60px;
            .row {
              margin: 0;
            }
          }
        }
      }
      .img-wrapper,
      .dots {
        display: ${props =>
          props.breackpoint === '2'
            ? 'block'
            : props.breackpoint === '3'
            ? 'none'
            : 'block'};
      }
      .carousel-container {
        .story-card {
          width: ${props =>
            props.breackpoint === '2'
              ? 'calc(80% - 30px);'
              : props.breackpoint === '3'
              ? '100%'
              : 'calc(50% - 30px);'};
          height: ${props =>
            props.breackpoint === '2'
              ? 'auto;'
              : props.breackpoint === '3'
              ? '330px'
              : 'auto;'};
          &:before {
            display: ${props =>
              props.breackpoint === '2'
                ? 'flex'
                : props.breackpoint === '3'
                ? 'none'
                : 'flex;'};
          }
        }
      }
      .dots-container {
        margin-top: ${props =>
          props.breackpoint === '2'
            ? '70px !important'
            : props.breackpoint === '3'
            ? '80px !important'
            : '22px'};
      }
      .desc-wrapper {
        width: ${props =>
          props.breackpoint === '2'
            ? '300px'
            : props.breackpoint === '3'
            ? '100%'
            : '300px'};
      }
      .carousel-desc-container {
        order: ${props =>
          props.breackpoint === '2'
            ? '1'
            : props.breackpoint === '3'
            ? '12'
            : '1'};
      }
    }
    #navigation {
      margin: 0 auto !important;
      width: 100%;
      .navbar {
        position: relative !important;
        z-index: 1;
        min-height: 75px;
      }
      .brand-wrapper {
        ${props =>
          (props.breackpoint === '2' || props.breackpoint === '3') &&
          css`
            box-shadow: none !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 0;
            padding: 16px;
            background: #fff;
            .navbar-toggler {
              border: none;
              display: block;
            }
            .nav-icon {
              width: 12px;
              height: 12px;
              position: relative;
              transform: rotate(0deg);
              transition: 0.5s ease-in-out;
              span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background: #7b848f;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;
                &:nth-child(1) {
                  top: 0px;
                }

                &:nth-child(2) {
                  top: 4px;
                }

                &:nth-child(3) {
                  top: 9px;
                }
              }
            }
            &.expanded {
              box-shadow: 0px 12px 16px rgba(106, 114, 158, 0.1);
              z-index: 10;
              .nav-icon {
                span {
                  &:nth-child(1) {
                    top: 5px;
                    transform: rotate(135deg);
                  }
                  &:nth-child(2) {
                    opacity: 0;
                    left: -20px;
                  }
                  &:nth-child(3) {
                    top: 5px;
                    transform: rotate(-135deg);
                  }
                }
              }
            }
          `}
      }
      .header-container {
        padding: 16px 0;
        .submenu-container {
          display: ${props =>
            props.breackpoint === '2'
              ? 'none !important'
              : props.breackpoint === '3'
              ? 'none !important'
              : 'block !important'};
        }
        .navbar-collapse {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              display: none !important;
              &.show {
                display: block !important;
              }
            `}
        }
        #basic-navbar-nav {
          margin-left: 35px;
          margin-right: 0;
          .navbar-nav {
            ${props =>
              (props.breackpoint === '2' || props.breackpoint === '3') &&
              css`
                flex-direction: column;
              `}
          }
        }
        ${props =>
          (props.breackpoint === '2' || props.breackpoint === '3') &&
          css`
            max-height: 100vh;
            overflow-y: auto;
            padding: 16px 0;
            padding-bottom: 0 !important;
            #basic-navbar-nav {
              width: 100%;
              margin: 38px 0 0 !important;
            }
          `}
        a[href*='/pricing'] {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              margin: auto 0 !important;
              &::after {
                display: none !important;
              }
            `}
        }
        .responsive {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              margin-right: 10px !important;
            `}
        }
        .menu-item {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              margin: 0 !important;
              border-bottom: 1px solid #f0f2f5;
              &:first-child {
                border-top: 1px solid #f0f2f5;
              }
              &.navLink {
                padding: 10px 16px;
                width: 100%;
              }
              .nav-header {
                padding: 12px 15px;
              }
              .dropdown-content {
                position: unset;
                box-shadow: unset;
                padding: 0 0 20px;
                &.show {
                  display: block !important;
                }
                .container {
                  display: block;
                  max-width: unset;
                  .row {
                    display: block;
                    .nav-section {
                      margin: 0;
                      padding: 24px 24px 16px;
                    }
                  }
                  .nav-section-items {
                    display: block;
                    .col {
                      max-width: unset;
                    }
                    .nav-item {
                      h6 {
                        ${font('text', 'sm', '400')}
                        margin: 0;
                      }
                      .subhead {
                        font-weight: 400;
                      }
                    }
                  }
                  .top-block {
                    p {
                      padding: 16px 0;
                      margin-bottom: 16px;
                      color: #7b848f;
                      border-bottom: 1px solid #f0f2f5;
                    }
                  }
                }
              }
            `}
        }
        .headerCTA {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              padding: 0 24px !important;
            `}
        }
        .nav-header {
          padding: 12px 15px;
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              width: 100%;
              padding: 10px 24px;
              line-height: 24px;
              color: ${props => (props.isDark ? '#fff' : 'var(--text-color)')};
              &::after {
                content: '';
                background-image: url(${caret});
                filter: invert(15%) sepia(73%) saturate(4470%)
                  hue-rotate(234deg) brightness(116%) contrast(111%);
                background-color: initial !important;
                height: 9px;
                width: 12px;
                display: block;
                position: absolute;
                border: none;
                background-repeat: no-repeat;
                top: 50%;
                right: 16px;
                left: initial;
                transform: translateY(-50%);
                transition: all 0.2s;
                opacity: 1 !important;
              }
            `}
        }
        .header-active {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              background: rgba(28, 63, 255, 0.05);
              &::after {
                top: 50%;
                right: 18px;
                transform: translateY(-50%) rotate(180deg);
              }
            `}
        }
        .removeBtn {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              .sign-in {
                display: none;
              }
              display: none;
              .separator {
                display: none;
              }
            `}
        }
        &.expanded {
          ${props =>
            (props.breackpoint === '2' || props.breackpoint === '3') &&
            css`
              .removeBtn {
                display: flex;
                margin: 0 auto;
                width: 100%;
                max-width: 100%;
                flex-direction: column;
                button {
                  width: auto !important;
                }
                a {
                  margin: 10px 0 !important;
                  width: 100%;
                  text-align: center;
                  display: inline-block;
                  text-decoration: none;

                  &.phone-wrapper {
                    display: none;
                  }
                  .btn {
                    width: 100%;
                  }
                }
              }
            `}
        }
        .top-block {
          p {
            font-size: 14.4px !important;
          }
        }
        .nav-item {
          .subhead {
            p {
              font-size: 12px !important;
            }
          }
        }
      }
      .navbar-expand-xl {
        ${props =>
          (props.breackpoint === '2' || props.breackpoint === '3') &&
          css`
            .container {
              flex-wrap: wrap;
            }
          `}
      }
    }
    @media (min-width: 991px) {
      .col-lg-2 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '50%'
              : '16.7%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '50%'
            : '16.7%'};
      }
      .col-lg-3 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '25%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '25%'};
      }
      .col-lg-4,
      .col-md-4 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '33.33333%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '33.33333%'};
      }
      .col-lg-5 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '41.66667%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '41.66667%'};
      }
      .col-lg-6 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '50%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '50%'};
      }
      .col-lg-7 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '58.33333%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '58.33333%'};
      }
      .col-lg-8,
      .col-md-8 {
        flex: 0 0
          ${props =>
            props.breackpoint === '2'
              ? '50%'
              : props.breackpoint === '3'
              ? '100%'
              : '66.66%'};
        max-width: ${props =>
          props.breackpoint === '2'
            ? '50%'
            : props.breackpoint === '3'
            ? '100%'
            : '66.66%'};
      }
    }

    h1 {
      font-size: 2.441rem !important;
    }
    h2 {
      font-size: 1.953rem !important;
    }
    h3 {
      font-size: 1.563rem !important;
    }
    h4 {
      font-size: 1.25rem !important;
    }
    .kicker,
    .headingKicker,
    .title-kicker,
    .switch-kicker,
    .social-reviews,
    .blog-heading-kicker {
      font-size: 12px !important;
    }
    .quote {
      p {
        font-size: 35.16px !important;
      }
    }

    .card-wrapper {
      a {
        text-decoration: none !important;
      }
      .subhead {
        p {
          font-size: 16.2px !important;
        }
      }
    }
    .stickyNav {
      box-shadow: none !important;
    }
    .reading-time {
      p {
        font-size: 14.4px !important;
      }
    }
    .split-swiper {
      .swiper-slide {
        width: ${props =>
          props.breackpoint === '2'
            ? '420px !important'
            : props.breackpoint === '3'
            ? '350px !important'
            : 'auto'};
      }
    }
  }
  .componentTitle {
    ${font('display', 'md', '700')}
    color: #1d2030;
    margin-bottom: 32px;
  }
  .feature-ul {
    list-style: none;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.27px;
    color: var(--text-color);
    max-width: 770px;
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 32px;
      display: inline-block;
      width: 235px;
      @media (max-width: 768px) {
        width: 50%;
      }
      &:after {
        content: ' ';
        display: block;
        width: 22px;
        height: 22px;
        background: url(${checkmark});
        position: absolute;
        left: 0;
        top: 0px;
      }
      &.closeMark {
        &:after {
          background: url(${closemark});
          background-repeat: no-repeat;
          left: 4px;
          top: 4px;
        }
      }
    }
  }
  .guideline-title {
    ${font('display', 'md', '700')}
    color: #1c3fff;
    margin-bottom: 32px;
  }
  .guideline-ul {
    list-style: none;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.16px;
    color: var(--text-color);
    width: 270px;
    @media (max-width: 991px) {
      width: 100%;
    }
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 32px;
      &:after {
        content: ' ';
        display: block;
        width: 15px;
        height: 15px;
        background: url(${plusmark});
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 3px;
      }
      &:last-child {
        margin-bottom: 0;
        @media (max-width: 991px) {
          margin-bottom: 32px;
        }
      }
    }
  }
  .linkTitle {
    margin-bottom: 32px;
    @media (max-width: 991px) {
      font-size: 35.16px;
    }
  }
`;
